.itemProduct {
  width: 220px;
  height: 350px;
  border: 3px solid #d8d8d8;
  border-radius: 10px;
  margin: 10px 10px;
  @include breakpoints(lg) {
    width: 250px;
    height: 370px;
  }
  &__title {
    text-align: center;
    margin: 5px 10px;
    height: 40px;
  }
  &__number {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    @include breakpoints(lg) {
      margin: 20px 0px;
    }
    &__counter {
      display: flex;
      align-items: center;
      &__less {
        @include button_counter();
      }
      p {
        font-size: 16px;
        margin: 0 5px;
      }
      &__more {
        @include button_counter();
      }
    }
  }
  &__image {
    width: 200px;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__button {
    display: flex;
    align-items: center;

    &__add {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      margin: 0 0 0 auto;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary-color;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin: 5px 7px 5px 10px;
        width: 150;
        height: 40px;
        color: #ffffff;
        margin: 0;
        font-size: 14px;
        &:hover {
          background-color: $primary-color;
        }
        @include breakpoints(lg) {
          margin: 0 5px;
          font-size: 16px;
        }
        svg {
          margin: 0 5px;
        }
      }
    }
    &__favorite {
      height: 35px;
      width: 40px;
      border-radius: 5px;
      border: 1px solid #ef5d5d;
      display: flex;
      align-items: center;
    }
  }
}

.contactFormInput {
  margin: 20px 5%;
  @include breakpoints(sm) {
    margin: 20px 5%;
  }
  @include breakpoints(md) {
    margin: 20px 10%;
  }
  @include breakpoints(lg) {
    margin: 20px 5%;
  }
  &__form {
    &__input {
      margin: 20px 0px;
      p {
        text-align: left;
        margin: 5px 0px;
        font-size: 14px;
        @include breakpoints(sm) {
          font-size: 16px;
        }
        @include breakpoints(md) {
          font-size: 18px;
        }
        @include breakpoints(lg) {
          font-size: 20px;
        }
      }
      input {
        width: 92%;
        height: 40px;
        font-size: 15px;
        padding: 0px 10px;
        border: none;
        border-bottom: 3px solid $primary-color;
        @include breakpoints(sm) {
          width: 95%;
        }
        &::placeholder {
          font-size: 15px;
          padding-left: 10px;
        }
        &:focus {
          outline: none;
          background: linear-gradient(to bottom, transparent, #f2f2f2);
        }
      }
    }
    &__button {
      text-align: center;
      button {
        width: 150px;
        font-size: 15px;
        height: 35px;
        border-radius: 10px;
        cursor: pointer;
        border: none;
        &:hover {
          color: #ffffff;
          background-color: $primary-color;
        }
      }
    }
  }
}

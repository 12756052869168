.itemList {
  position: relative;
  line-height: 30px;
  padding-bottom: 20px;
  font-size: 25px;
  text-align: justify;
  left: 50px;
  a {
    text-decoration: none;
    color: #ffffff;
  }
  @include breakpoints(md) {
    top: 0px;
    float: left;
    text-align: center;
    border-radius: 10px;
    background-color: $primary-color;
    font-size: 20px;
    padding: 5px 14px;
    margin-left: 15px;
  }
  @include breakpoints(lg) {
    top: 0px;
    float: left;
    text-align: center;
    border-radius: 10px;
    background-color: $primary-color;
    font-size: 20px;
    padding: 7px 14px;
    margin-left: 15px;
  }
}

.headerDesktop {
  display: none;
  @include breakpoints(md) {
    display: flex;
    justify-content: space-evenly;
    &__title {
      position: relative;
      margin: 0;
      width: 23.9%;
      a {
        font-family: $font-logo;
        text-decoration: none;
        font-size: 50px;
        color: $primary-color;
        @include breakpoints(lg) {
          font-size: 60px;
        }
      }
    }
    &__navBar {
      width: 64.1%;
      position: relative;
      top: 10px;
      margin: 0;
      @include breakpoints(lg) {
        padding-left: 30%;
        margin: 0;
        top: 15px;
      }
    }
    &__icon {
      display: flex;
      justify-content: space-evenly;
      width: 12%;
      position: relative;
      top: 8px;
      height: 50px;
      margin: 0;
      @include breakpoints(lg) {
        margin: 0;
        top: 15px;
      }
      &__totalItem {
        position: absolute;
        top: -3px;
      }
      a {
        margin: 0px 5px;
      }
    }
  }
}

.headerMobile {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  background-color: #ffffff;
  height: 70px;
  top: 0px;
  min-width: 250px;
  @include breakpoints(sm) {
    min-width: 576px;
  }
  &__icon {
    &__totalItem {
      position: absolute;
      top: 10px;
      left: 79%;
      @include breakpoints(sm) {
        left: 76%;
      }
    }
    a {
      margin: 0px 10px;
      position: relative;
      top: 16px;
      left: 45px;
      @include breakpoints(sm) {
        left: 140px;
      }
    }
  }
  &__title {
    a {
      font-family: $font-logo;
      text-decoration: none;
      font-size: 40px;
      position: relative;
      top: 13px;
      color: $primary-color;
      right: 10px;
      @include breakpoints(sm) {
        right: 40px;
      }
    }
  }
  @include breakpoints(md) {
    display: none;
  }
}

.homeWelcome {
  background-color: $primary-color;
  display: block;
  font-family: $font-secondary;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  @include breakpoints(sm) {
    display: flex;
    justify-content: space-evenly;
  }
  &__title {
    padding: 10px;
    margin: 0;
    @include breakpoints(sm) {
      width: 50%;
      padding: 20px;
    }
    h1 {
      font-size: 30px;
      @include breakpoints(sm) {
        padding-top: 30px;
        font-size: 40px;
      }
      @include breakpoints(md) {
        padding-top: 10px;
        font-size: 50px;
      }
      @include breakpoints(lg) {
        padding-top: 40px;
        font-size: 60px;
      }
    }
    p {
      font-size: 15px;
      @include breakpoints(sm) {
        font-size: 18px;
      }
      @include breakpoints(md) {
        font-size: 20px;
        padding: 5px 10px;
      }
      @include breakpoints(lg) {
        font-size: 32px;
        padding: 5px 20px;
      }
    }
  }
  &__image {
    @include breakpoints(sm) {
      margin: 0;
    }
    & img {
      border-radius: 25px;
      object-fit: cover;
      width: 300px;
      padding-top: 15px;
      padding-bottom: 10px;
      @include breakpoints(sm) {
        width: 200px;
      }
      @include breakpoints(md) {
        max-width: 420px;
      }
      @include breakpoints(lg) {
        width: 600px;
      }
    }
  }
}

* {
  margin: 0 auto;
}
header {
  max-width: 90rem;
}
main {
  max-width: 90rem;
}
footer {
  max-width: 90rem;
}

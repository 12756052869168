@mixin title($top: 0) {
  position: absolute;
  text-decoration: none;
  display: block;
  top: $top;
  color: #ffffff;
  font-size: 23px;
  left: 40px;
}
.navBarMobile {
  position: fixed;
  font-family: Arial, sans-serif;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $primary-color;
  opacity: 0;
  pointer-events: none;
  
  &__close {
    position: absolute;
    right: 40px;
    top: 40px;
  }
  &:target {
    opacity: 1;
    pointer-events: auto;
  }
  &:target &__movedown {
    margin: 40% auto;
  }
  &__item {
    list-style: none;
  }
}

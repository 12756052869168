.cartDetailItemProduct {
  display: flex;
  background-color: #ffffff;
  margin: 20px auto;
  border-radius: 10px;
  width: 330px;
  height: 180px;
  justify-content: space-evenly;
  align-items: center;
  @include breakpoints(sm) {
    width: 550px;
    height: 160px;
  }
  @include breakpoints(md) {
    width: 550px;
    height: 160px;
  }
  @include breakpoints(lg) {
    width: 700px;
    height: 180px;
  }
  &__image {
    width: 25%;
    height: 120px;
    @include breakpoints(sm) {
      width: 30%;
    }
    img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__information {
    width: 25%;
    text-align: center;
    @include breakpoints(sm) {
      width: 25%;
    }
  }
  &__price {
    width: 20%;
    text-align: center;
    @include breakpoints(sm) {
      width: 25%;
    }
  }
  &__counter {
    width: 30%;
    @include breakpoints(sm) {
      width: 20%;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    &__less {
      @include button_counter($margin: 15px 5px);
    }
    &__more {
      @include button_counter();
    }
    &__delete {
      width: 90px;
      background-color: $secondary-color;
      border: none;
      color: #ffffff;
      height: 30px;
      border-radius: 10px;
      cursor: pointer;
      margin: 10px auto;
      @include breakpoints(sm) {
        width: 100px;
      }
      &:hover {
        background-color: $primary-color;
      }
    }
  }
}

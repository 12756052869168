.basicLayout {
  header {
    text-align: center;
    position: sticky;
    top: 0px;
    background-color: #ffffff;
  }
  footer {
    text-align: center;
    background-color: $quaternary-color;
  }
}

.cartDetailSummaryCard {
  background-color: #ffffff;
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  &__button {
    button {
      width: 100px;
      height: 30px;
      border: none;
      border-radius: 10px;
      color: #ffffff;
      background-color: $secondary-color;
      cursor: pointer;
      &:hover {
        background-color: $primary-color;
      }
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Rubik+Dirt&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi+Fun:wght@500;600;700&display=swap");

$font-logo: "Rubik Dirt", cursive;
$font-secondary: "Reem Kufi Fun", sans-serif;
$primary-color: #e26868;
$secondary-color: #ff8787;
$tertiary-color: #ededed;
$quaternary-color: #d8d9cf;

@mixin breakpoints($point) {
  @if $point == sm {
    @media (min-width: 36em) {
      @content;
    }
  }
  @if $point == md {
    @media (min-width: 57.5em) {
      @content;
    }
  }
  @if $point == lg {
    @media (min-width: 80em) {
      @content;
    }
  }
}
@mixin button_counter($margin: 0 5px, $width: 30px, $height: 30px) {
  font-size: 20px;
  margin: $margin;
  width: $width;
  height: $height;
  border: 1px solid #ef5d5d;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ef5d5d;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
  }
}

.totalItemAdd {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 25px;
  height: 25px;
  background-color: $primary-color;
  color: #ffffff;
  border-radius: 15px;
  font-size: 14px;
}

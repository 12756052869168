@mixin section($padding: 10px) {
  margin: 20px 0px;
  min-width: 200px;
  text-align: justify;
  position: relative;
  padding: $padding;
  @include breakpoints(lg) {
    min-width: 280px;
  }
}
@mixin title {
  font-size: 17px;
  font-family: $font-secondary;
  position: relative;
  font-weight: 700;
  text-align: center;
}
.footerDesktop {
  display: none;
  @include breakpoints(md) {
    display: flex;
    justify-content: space-evenly;
  }
  img {
    padding-left: 15px;
  }
  &__logo {
    @include section();
    &__title {
      font-family: $font-logo;
      position: relative;
      bottom: 40px;
      left: 65px;
    }
    &__subtitle {
      font-family: $font-secondary;
      position: relative;
      bottom: 46px;
      left: 65px;
    }
  }
  &__social {
    @include section();
    &__title {
      @include title();
    }
  }
  &__contact {
    @include section();
    &__title {
      @include title();
    }
    &__paragraph {
      font-family: $font-secondary;
      font-size: 13px;
      padding-left: 15px;
    }
  }
  &__newsletter {
    @include section($padding: 10px 0px);
    &__title {
      @include title();
    }
    &__form {
      display: flex;
      flex-wrap: wrap;
      input {
        height: 25px;
        border-radius: 5px;
        flex-grow: 1;
        margin: 5px;
      }
      button {
        margin: 5px;
        min-width: 100px;
        height: 30px;
        border: none;
        background-color: $primary-color;
        border-radius: 5px;
        flex-grow: 1;
        cursor: pointer;
        color: #ffffff;
        @include breakpoints(lg) {
          min-width: 30px;
        }
      }
    }
  }
}
